











































import { Component } from 'vue-property-decorator';
import ReviewAssignment from '@/models/reviews/ReviewAssignment';
import ReviewAssignmentService from '@/services/ReviewAssignmentService';
import moment from 'moment';
import AssignmentDetailsModal from '@/components/modals/AssignmentDetailsModal.vue';
import { getCurrentUserID, hasPermission } from '@/auth/AuthService';
import { PossibleAction } from '@/auth/PossibleAction';
import { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';

@Component({
  components: { AssignmentDetailsModal },
})
export default class Projects extends mixins(Toasts) {
  protected readonly breadcrumbs = [
    { text: 'Home', to: '/' },
    { text: 'Review Assignments', active: true },
  ];

  protected currentUserID = '';

  protected assignments: ReviewAssignment[] = [];
  protected selectedAssignment: ReviewAssignment | null = null;

  mounted(): void {
    if (hasPermission(PossibleAction.CAN_NAVIGATE_REVIEWS)) {
      this.currentUserID = getCurrentUserID();

      this.$on('finished-delete-assignment', (args) => {
        this.assignments.splice(this.assignments.indexOf(args.assignment), 1);
      });

      this.$on('finished-update-assignment', (args) => {
        if (this.selectedAssignment) {
          this.selectedAssignment.state = args.newState;
          this.selectedAssignment.approved = true;
        }
      });

      this.loadAssignmentsOfUser();
    } else {
      this.$router.replace({ path: '/' });
    }
  }

  beforeDestroy(): void {
    this.$off('finished-delete-assignment');
    this.$off('finished-update-assignment');
  }

  protected loadAssignmentsOfUser(): void {
    if (hasPermission(PossibleAction.CAN_GET_REVIEW)) {
      this.setLoading(true);
      ReviewAssignmentService.getAllByOwnerID(this.currentUserID)
        .then((assignments) => {
          this.assignments = assignments.reverse();
          this.showToast('Successfully loaded', assignments.length + ' Assignments found.', 'info');
        })
        .catch((backendError) => {
          if (backendError.response.status === 403) {
            this.showToast('Action denied', 'You do not have the required rights.', 'danger');
          } else {
            this.showToast('Failed to load', 'Could not load assignments.', 'danger');
          }
        })
        .finally(() => this.setLoading(false));
    } else {
      this.showToast('Action denied', 'You do not have the required rights.', 'danger');
    }
  }

  protected showAssignmentDetails(assignment: ReviewAssignment): void {
    this.selectedAssignment = assignment;
    this.$bvModal.show('modal-show-assignment');
  }

  protected format(date: Date): string {
    return moment(date).format('DD.MM.YYYY HH:mm');
  }

  protected setLoading(loading: boolean): void {
    this.$root.$data.loading = loading;
  }
}
